<script lang='ts'>
    export let url = "";
    export let icon = "";
    export let title = "";
    export let text = "";
</script>

<div class="shadow" style="display: flex; flex-direction: column; background-color: #fff; border-radius: 0.5em;">
    <a href="{url}">
        <div style="background-image: url('{icon}');  background-size: 100%; background-position: center; 
                background-repeat: no-repeat; width: 100%; min-height: 128px; border-top-left-radius: 0.5em; 
                border-top-right-radius: 0.5em;"></div>
        <div style="margin: 0.5em;">
            <h4>{title}</h4>
            <p>{text}</p>
        </div>
    </a>
</div>

<style>
    .shadow {
        box-shadow: rgba(0, 0, 0, 0.4) 0px 0px 0.5em;    
    }
</style>