<script lang='ts'>
    import { icon, type IconDefinition } from '@fortawesome/fontawesome-svg-core'
    
    export let iconDefinition: IconDefinition | null = null;
    export let style = "";
    export let classes: string[] = [];

    let element: HTMLElement | null = null;
    $: {
        if (element) {
            let elements = (iconDefinition ? Array.from(icon(iconDefinition).node) : []);
            for (let e of elements) {
                e.style.cssText = style;
                e.classList.add(...classes);
            }
            element.replaceChildren(...elements);
        }
    }
</script>

<i bind:this={element}/>
