import Homepage from "./Homepage.svelte";

export function createSvelteUI(target, cards, user) {
    new Homepage({
        target: target,
        props: {
            user: user,
            cards: cards
        }
    });
}
