<script lang='ts'>
    import Card from "./Card.svelte";
    import Nav from "../../vrengine/ts/src/util/svelte/Nav.svelte";

    export let user: any = {};
    export let cards: any[] = [];
</script>

<div style="padding: 0 1em 0 1em; max-width: 1200px; margin: auto;">
    <Nav user={user}></Nav>

    <div class="grid">
        {#each cards as card}
        <Card {...card}/>
        {/each}
    </div>
</div>

<style>
    .grid {
        display: grid; 
        grid-gap: 1.5em; 
        grid-template-columns: repeat(auto-fill, minmax(256px, 1fr)); 
        margin: auto;
    }

</style>